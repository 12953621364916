<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Vuexy
          </h2> -->

          <b-img
            :src="appLogoImage"
            alt="logo"
            style="width:240px"
          />
        </span>

        </b-link>

        <b-card-title class="mb-1">
          Welcome to EL Taskforce!
        </b-card-title>
        <b-card-text class="mb-2">
          Please Change your default account password and start the adventure
        </b-card-text>

        <b-alert
            v-model="showDismissibleAlert"
            variant="danger"
            dismissible
            class="mb-0"
          >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <!-- form -->
        
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="updatePassword"
          >

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                
              </div>
              
                <b-input-group
                  class="input-group-merge"
                  
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
               
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="cpassword">Confirm Password</label>
              </div>
              
                <b-input-group
                  class="input-group-merge"
                  
                >
                  <b-form-input
                    id="c_password"
                    v-model="c_password"
                    :type="cpasswordFieldType"
                    class="form-control-merge"
                    
                    name="clogin-password"
                    placeholder="Confirm Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="cpasswordToggleIcon"
                      @click="toggleCPasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
               
            </b-form-group>

            
            <b-button
              variant="warning"
              type="submit"
              block
              
            >
              Submit
            </b-button>

            <!-- <b-button
              variant="primary"
              
              block
              
            >
              Skip
            </b-button> -->
          </b-form> 

          <b-card-text class="text-center mt-2">
            <!-- <span>Already have an account? </span> -->
            <b-link @click="skipStep">
              <span>Skip</span>
            </b-link>
          </b-card-text>    

        
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>

import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, BAlert,BImg
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { LOGIN,CHANGE_PASSWORD } from "../store/actions.type"

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BAlert,
    BImg
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      c_password: '',
      showDismissibleAlert:false,
      error_message:'',
      skip:false,
      passwordToggleIcon:'EyeIcon',
      cpasswordToggleIcon:'EyeIcon',
      passwordFieldType:'password',
      cpasswordFieldType:'password',

      appLogoImage : require('@/assets/images/logo/task-force-full.png'),

    }
  },
  
  methods: {
    toggleCPasswordVisibility(){
      if (this.cpasswordFieldType == 'password') {
          this.cpasswordToggleIcon = 'EyeOffIcon';
          this.cpasswordFieldType = 'text';
      }else{
          this.cpasswordToggleIcon = 'EyeIcon';
          this.cpasswordFieldType = 'password';
      }
    },

    togglePasswordVisibility(){

      if (this.passwordFieldType == 'password') {
          this.passwordToggleIcon = 'EyeOffIcon';
          this.passwordFieldType = 'text';
      }else{
          this.passwordToggleIcon = 'EyeIcon';
          this.passwordFieldType = 'password';
      }
    },
    updatePassword() {

      this.$store.dispatch(CHANGE_PASSWORD, {
                        data:{
                            password:this.password,
                            confirm_password:this.c_password,
                            user_id: this.$store.getters.currentUser._id,
                            role: this.$store.getters.currentUser.role,
                            skip:this.skip,
                        },
                        api:'/api/client-account-password-change'
                    })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
              
              var userData = this.$store.getters.currentUser;
              
              userData.ability = [{
                action: "manage", subject: "all"
              }];

              userData.menu_permission = this.$store.getters.currentUser.menu_permission;

              localStorage.setItem('userData', JSON.stringify(userData))
              
              this.$ability.update(userData.ability)

              if (this.skip == false) {

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Password Changed Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    
                    if (userData.role == 'super_admin') {
                      window.location.replace('/admin/dashboard');
                    }
                    /*else if (userData.role == 'site_client') {
                      window.location.replace('/site/dashboard');
                    }*/else{
                      window.location.replace('/client/dashboard');

                    }
                });

              }else{

                    if (userData.role == 'super_admin') {
                      window.location.replace('/admin/dashboard');
                    }
                    /*else if (userData.role == 'site_client') {
                      window.location.replace('/site/dashboard');
                    }*/else{
                      window.location.replace('/client/dashboard');

                    }

              }
            }       
        }); 
    },

    skipStep() {
      this.skip = true;
      this.updatePassword();
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
